@import "variables";

$slick-loader-path: "../img/" !default;
$slick-font-path: "../fonts/" !default;

$fa-font-path: "../fonts" !default;

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
@import "bower_components/slick-carousel/slick/slick-theme.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower



@import "mixins";

//Main site structure
@import "structure";


/*fonts*/
@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700);

/*body {
	background-color:$body;
	font-family:'Montserrat',sans-serif;
	color: $grey;
	font-weight: 400;
}*/

@font-face {
    font-family: SohneBuch;
    src: url("fonts/Sohne-Buch.otf") format("opentype");
}

@font-face {
    font-family: SohneKraftig;
    src: url("fonts/Sohne-Kraftig.otf") format("opentype");
}

@font-face {
    font-family: SohneHalbfett;
    src: url("fonts/Sohne-Halbfett.otf") format("opentype");
}

@font-face {
    font-family: SohneExtrafett;
    src: url("fonts/Sohne-Extrafett.otf") format("opentype");
}


body {
	background-color:$body;
	font-family:'SohneBuch';
	color: $grey;
	font-weight: 400;
}


header {
	
	-webkit-transition: background .3s ease;
-moz-transition: background 1s ease;
-ms-transition: background 1s ease;
-o-transition: background 1s ease;
transition: background 1s ease;
height:70px;
overflow: visible;
background: darken($brand-primary,5%); /* Old browsers */
background: -moz-linear-gradient(45deg,  darken($brand-primary,15%) 0%, lighten($brand-primary,10%) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,darken($brand-primary,15%)), color-stop(100%,lighten($brand-primary,10%))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(45deg,  darken($brand-primary,15%) 0%,lighten($brand-primary,10%) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(45deg,  darken($brand-primary,15%) 0%,lighten($brand-primary,10%) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(45deg,  darken($brand-primary,15%) 0%,lighten($brand-primary,10%) 100%); /* IE10+ */
background: linear-gradient(45deg,  darken($brand-primary,15%) 0%,lighten($brand-primary,10%) 100%); /* W3C */
.header-graphic {
		position: absolute;
		width: 100%;
		height: 100%;
		background: url(../img/sun-graphic.png) 165px 20% no-repeat ;
		top:0;
		left:0;
	}
}
blockquote {
	color: $brand-primary;
}


h1, h2, h3, h4 {
	font-family: inherit;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}
h1, h2, h3 {
	margin-top: 20px;
  margin-bottom: 10px;
}
h1 {
 margin: .67em 0;
  font-size: 2.8em;
  letter-spacing: -1px;
}
h2 {
	font-size:2em;
	letter-spacing: -1px;
}
h3 {
	font-size: 1.5em;
	
}
h4 {
	font-size: 1.25em;
	margin: 15px 0 10px 0;
	text-transform: uppercase;
}


.btn-default {
	background: $brand-secondary;
	color: $white;
	border-color: $brand-secondary;
	border-radius: 25px;
}
.btn-default:hover,.btn-default:focus,.btn-default:active {
	color:$white;
	background-color:darken($brand-secondary, 10%);
	border-color: $brand-secondary;
}
.btn-primary {
	background: $brand-primary;
	color: $white;
	border-color: $brand-primary;
	border-radius: 25px;
}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active {
	color:$white;
	background-color:darken($brand-primary, 15%);
	border-color: $brand-primary;
}
.btn-reverse:hover,.btn-.btn-reverse:focus,.btn-.btn-reverse:active {
	border-color:#EE3254;
	background-color:#EE3254;
}
/* .preview {
	background:$brand-primary;
}
.preview:hover {
	background-color:darken($brand-primary, 15%);
} */

.brand {
	background: url("../img/rebrand1121/logos/DEC logo-U1-white_rgb.svg") center center no-repeat;
	width: 206px;
	height: 76px;
	margin:-6px 0 0 0;
	display: block;
}
.no-svg .brand {
	background: url("../img/rebrand1121/logos/DEC logo-U1-white_rgb.svg") center center no-repeat;
}
.icon-search--toggle {
	background: darken($brand-primary, 10%);
}
.search__container .search__inside {
	background: darken($light-pink, 10%);
	.form-control {
		border: 1px solid $light-pink;
		background: darken($light-pink, 15%);
		padding: 8px 12px 8px 30px;
		height: 45px;
	}
	.form-control:focus {
	background: darken($light-pink, 15%);
}
}

#footer {
	background: $purple;
	//a {
	//	color: $footer-link;
	//}

//	.footer-links {
//	li {
//		a {
//
//			background: $grey;
//		}
//		a:hover, a:focus {
//			background: darken($grey, 15%);
//		}
//	}
//	
//}
.btn-reverse {
	color: $white;
}
.btn-reverse:hover, .btn-.btn-reverse:focus, .btn-.btn-reverse:active {
		background-color: darken($grey, 15%);
	}
}

/* .preview {
	color: $brand-primary;
}
.preview:hover {
	color: darken($brand-primary, 15%);
} */

.slick-prev:before, .slick-next:before {
	color: $grey;
}



@media (min-width:768px) {
	header {
	
	.header-graphic {
		position: absolute;
		width: 100%;
		height: 100%;
		background: url(../img/sun-graphic.png) 80% 50% no-repeat;
		top:0;
		left:0;
	}
}
	.search__container {
		.search__inside {
			background: none;
			.form-control {
				background: $light-pink;
				border-color: $light-pink;
				-webkit-box-shadow: none;
box-shadow: none;
			}
			
		}
	}
	
}
@media (min-width: 768px) and (max-width: 991px) {
	.brand {
		margin-bottom: 10px;
	}
}
@media (min-width:992px) {
	header {
		height:67px;
	}
}

