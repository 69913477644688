
/* Structure variables */

$tint1: #cccccc;
$white: #ffffff;
$black: #333333;
$rich-black: #000000;
$grey: #555555;
$light-grey: #999999;
$trans-black1: rgba(0,0,0,.6);
$trans-black-fallback: #333333;
$trans-black2: rgba(0,0,0,.5);
$body: #eeeeee;


$padding-base-vertical:     14px;
$padding-base-horizontal:   12px;

$padding-large-vertical:    16px;
$padding-large-horizontal:  22px;




$border-radius-base:        0px;
$border-radius-large:       0px;
$border-radius-small:       0px;


/* Theme variables */



/*Colors*/
$white: #ffffff;
$body: #eeeeee;
$grey: #425a68;
$light-grey: lighten($grey, 20%);
$brand-primary: #5350A2;
$brand-secondary: #F05B89;
$trans-black1: rgba(0,0,0,.8);
$trans-black-fallback: #333333;
$trans-black2: rgba(0,0,0,.5);

$ntix-orange: #ec7623;

$link-color:            $brand-secondary;

$link-hover-color:      darken($link-color, 15%);
$footer-link: lighten($grey, 50%);


/* Button colors */
$btn-default-color:              $white;
$btn-default-bg:                 $brand-secondary;
$btn-default-border:             $white;

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $white;


$light-pink: #ea4e6d;
$mid-blue: #1789CA;
$purple: #5350A2;
$yellow: #FEDF5D;



